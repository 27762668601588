import React from 'react';
import { Carousel as OriginalCarousel, SlidesIndicator } from '@fiverr-private/carousel';

// eslint-disable-next-line react/prop-types
const Carousel = ({ withSlidesIndicator, children, ...props }) => (
    <OriginalCarousel {...props}>
        {withSlidesIndicator && <SlidesIndicator />}
        {children}
    </OriginalCarousel>
);
export default Carousel;
